import { createContext } from "react";

export const initialState = {
  questions: [],
  sections: [],

  code: null,
  name: "",
  loading: false,
  isNewGame: false,

  isWaitingForOtherUser: false,
  sectionIsComplete: true,
  page: "INDEX",

  error: false,

  // Answers from yours
  yourAnswers: {},
  theirAnswers: {},

  theirNickname: null,

  // All player data.
  otherPlayerId: null,

  yourSectionIndex: -1,
  theirSectionIndex: -1,

  player1: {},
  player2: {},

  serverActiveSectionIndex: -1,

  // Progress through questions.
  currentActiveSectionIndex: 0,
  section: 0,
  question: 0,
  currentQuestionIndex: 0,

  // What player ID am I?
  playerId: 1,

  // Global heading.
  heading: null,

  filter: null,
  sortBy: null,
  filterBy: null,
  filteredResults: [],
};

export function reducer(state, action) {
  switch (action.type) {
    // Code
    case "SET_QUESTIONS":
      return {
        ...state,
        questions: action.payload,
      };
    // Code
    case "SET_SECTIONS":
      return {
        ...state,
        sections: action.payload,
      };

    // Code
    case "SET_CODE":
      return {
        ...state,
        code: action.payload,
      };
    // Code
    case "SET_PLAYER":
      return {
        ...state,
        playerId: action.payload,
        otherPlayerId: action.payload === 1 ? 2 : 1,
      };

    // Nickanme of current user.
    case "SET_IS_NEW_GAME":
      return {
        ...state,
        isNewGame: action.payload,
      };

    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };

    case "SET_IS_WAITING":
      return {
        ...state,
        isWaitingForOtherUser: action.payload,
      };

    case "SET_SECTION_COMPLETE":
      return {
        ...state,
        sectionIsComplete: action.payload,
      };

    case "NEXT_SECTION":
      return {
        ...state,
        currentActiveSectionIndex: state.currentActiveSectionIndex + 1,
        isWaitingForOtherUser: false,
        sectionIsComplete: false,
        question: 0,
        // heading: state.sections[state.currentActiveSectionIndex].label
      };

    // Sync the question states.
    // This takes in a response from the server.
    case "SET_SESSION":
      let theirAnswers = {};
      let theirSectionIndex = 0;
      if (action.payload[state.otherPlayerId]) {
        theirAnswers = action.payload[state.otherPlayerId].answers ?? {};
        theirSectionIndex =
          typeof action.payload[state.otherPlayerId].section !== "undefined"
            ? action.payload[state.otherPlayerId].section
            : -1;
      }

      let yourSectionIndex = 0;
      let yourAnswers = {};
      if (action.payload[state.playerId]) {
        yourAnswers = action.payload[state.playerId].answers ?? {};
        yourSectionIndex =
          typeof action.payload[state.playerId].section !== "undefined"
            ? action.payload[state.playerId].section
            : -1;
      }

      // If the states are the same, then technically both should move onto the next step.
      // So if both have positions set to 0 (to start), then let's push them onto the next.
      return {
        ...state,
        yourAnswers,
        theirAnswers,
        theirSectionIndex,
        yourSectionIndex,
        player1: action.payload[1],
        player2: action.payload[2],
        serverActiveSectionIndex: action.payload.section,
        theirNickname: action.payload[state.otherPlayerId].nickname,
      };

    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
      };
    case "SET_PAGE":
      return {
        ...state,
        page: action.payload,
      };
    case "SET_QUESTION":
      return {
        ...state,
        question: action.payload,
      };

    case "INCR_SECTION":
      return {
        ...state,
        yourSectionIndex: state.yourSectionIndex + 1,
      };
    case "SET_ANSWER":
      const newQuestionIndex = state.question + 1;

      return {
        ...state,
        yourAnswers: {
          ...state.yourAnswers,
          [state.currentActiveSectionIndex]: {
            ...state.yourAnswers[state.currentActiveSectionIndex],
            [state.question]: action.payload,
          },
        },
        question: newQuestionIndex,
      };
    case "SET_WAITING_FOR_ANSWER":
      return {
        ...state,
        isWaitingForOtherUser: action.payload,
      };
    // Code
    case "SET_HEADING":
      return {
        ...state,
        heading: action.payload,
      };

    case "SET_SORT_ORDER":
      return {
        ...state,
        sortBy: action.payload,
      };
    case "SET_FILTER_BY":
      return {
        ...state,
        filterBy: action.payload,
        filteredResults: [],
      };
    default:
      return state;
  }
}

export const StateContext = createContext();
export const DispatchContext = createContext();
