import React from "react";
import PropTypes from "prop-types";

import { StyledCard, Heading, Select, Answer, TextAfter } from "./Card.style";

import Tick from "./icons/Tick";
import Cross from "./icons/Cross";
import NotSure from "./icons/NotSure";
import HighFive from "./icons/HighFive";

const Card = ({ heading, answerCallback, disabled, answers, ...rest }) => {
  const handleAnswer = (e) => {
    answerCallback(e.currentTarget.dataset.id, e);
  };

  const icons = {
    tick: Tick,
    cross: Cross,
    unsure: NotSure,
    highfive: HighFive,
  };

  return (
    <StyledCard disabled={disabled} {...rest}>
      <Heading>{heading}</Heading>
      <Select>
        {answers.map((item) => {
          const IconInstance = icons[item.icon];

          const defaultColor = item.disabled ? "#eee" : item.color;

          return (
            <Answer
              key={item.value}
              data-id={item.value}
              disabled={item.disabled}
              onClick={answerCallback && !disabled ? handleAnswer : null}
            >
              <IconInstance color={defaultColor} filled={item.filled} />
              {item.after && (
                <TextAfter color={item.afterColor}>{item.after}</TextAfter>
              )}
            </Answer>
          );
        })}
      </Select>
    </StyledCard>
  );
};

Card.propTypes = {
  heading: PropTypes.string,
  answerCallback: PropTypes.func,

  answers: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      value: PropTypes.isRequired,
      icon: PropTypes.oneOf(["tick", "cross", "notsure", "highfive", "unsure"]),
    })
  ).isRequired,
};

export default Card;
