import React, { useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { 
    StyledProgress,
    Items,
    Item,
    Image
} from './Progress.style';



const Progress = ({ questions, activeSection, ...rest }) => {


    // Scroll to active item on mobile.
    useLayoutEffect(() => {
        if (activeSection) {
            const item = document.querySelector('[data-step="' + activeSection + '"]');

            if (item) {
                document.querySelector('[data-step="' + activeSection + '"]').scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                    inline: 'center'
                });
            }
        }
    }, [activeSection]);
 

    return (
        <StyledProgress { ...rest }>
          <Items>
              { questions.map((res, index) => (
                  <Item className="js-step" data-step={ index } active={ index === activeSection } complete={ index < activeSection }><Image src={ res.image } /></Item>
              ))}
          </Items>
        </StyledProgress>
    );
}

Progress.propTypes = {
}

Progress.defaultProps = {
    color: 'green'
}

export default Progress;