import React from 'react';
import PropTypes from 'prop-types';
import { 
    Dot,
    StyledLoader
} from './Loader.style';



const Loader = ({ children, color, ...rest }) => {

    return (
        <StyledLoader { ...rest }>
            <Dot />
            <Dot />
            <Dot />
            <Dot />
            <Dot />
        </StyledLoader>
    );
}

export default Loader;