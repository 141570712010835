import styled from "styled-components/macro";

export const StyledInstruction = styled.div`
  margin-top: 10px;
  width: 100%;
`;

export const Content = styled.div`
  text-align: center;
  font-weight: normal;
  font-size: 14px;
`;
