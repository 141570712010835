import React from 'react';
import { 
    StyledTextField
} from './TextField.style';



const TextField = ({ ...rest }) => {
    return (
        <StyledTextField { ...rest } />
    );
}

export default TextField;