import styled from "styled-components/macro";

import HighFiveIcon from "./assets/high-five.svg";
import { device } from "../../theme.js";
export const ProgressWrapper = styled.div`
  max-width: 710px;
  margin: 0 auto;
  padding-bottom: 40px;

  padding-top: 20px;
  display: flex;
  justify-content: center;
`;

export const CardList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* width:auto;  */
  flex-grow: 1;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    display: grid;
    justify-content: center;
    flex-direction: row;
    grid-template-columns: 50% 50%;
  }

  @media ${device.laptop} {
    display: grid;
    justify-content: center;
    flex-direction: row;
    grid-template-columns: 25% 25% 25% 25%;
    align-items: flex-start;
  }

  @media ${device.laptopL} {
    display: grid;
    justify-content: center;
    flex-direction: row;
    grid-template-columns: 25% 25% 25% 25%;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;

  max-width: 320px;

  &:nth-child(2n) {
    margin-right: 0;
  }

  @media ${device.tablet} {
    margin-right: 20px;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media ${device.laptop} {
    max-width: 300px;
    height: 100%;
    margin-right: 20px;

    &:nth-child(2n) {
      margin-right: 20px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media ${device.laptopL} {
    max-width: 300px;
  }
`;

export const Header = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const SummaryWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  align-items: stretch;
`;

export const Summary = styled.div`
  width: 48%;
  @media ${device.tablet} {
    &:nth-child(2) {
      order: -1;
    }
    width: 20%;
  }

  align-self: flex-start;
  text-align: ${(props) => (props.right ? "right" : "left")};
`;

export const SummaryPlayerName = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 15px;
`;

export const SummaryIntro = styled.h3`
  display: flex;
  flex-direction: ${(props) => props.direction ?? "row"};
  align-items: center;
  ${SummaryPlayerName} {
    ${(props) =>
      props.direction === "row-reverse"
        ? "margin-right:15px"
        : "margin-right:15px"};
  }
  padding: 0;
  margin: 0;
`;

export const SummaryPlayerIcon = styled.img`
  max-width: 100%;
  height: auto;
`;

export const SummaryHeading = styled.div`
  font-size: 1.1rem;
  color: #39cae0;
  letter-spacing: -0.8px;
  line-height: 1.4375;
  font-weight: 700;
  padding-top: 20px;
`;
export const SummaryDescription = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
`;

export const PieWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 300px;
  @media ${device.tablet} {
    width: auto;
    min-height: none;
  }
`;

export const ResultsHeader = styled.div`
  /* border-bottom:1px solid rgba(151,151,151, .5); */
  padding-bottom: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;

  justify-content: space-between;
  @media ${device.tablet} {
    align-items: center;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 20px;
  font-weight: bold;
  font-size: 0.875rem;
  align-items: center;

  @media ${device.tablet} {
    width: 65%;
  }

  @media ${device.laptop} {
    width: 65%;
  }

  @media ${device.laptopL} {
    width: 50%;
  }
`;

export const FilterLabel = styled.div`
  font-size: 0.875rem;
  color: #3d434f;
  letter-spacing: 0;

  @media ${device.tablet} {
    width: auto;
    color: #898989;
    padding-right: 25px;
  }
`;

export const FilterOptions = styled.div`
  position: relative;
`;

export const FilterOption = styled.button``;

export const ResultsHeading = styled.h2`
  font-size: 1.25rem;
  color: #008067;
  letter-spacing: -1.7px;
  display: flex;
  line-height: 1;
  vertical-align: middle;
  align-items: center;
  margin: 0;
  text-align: center;
  padding: 0;
  &::before {
    content: "";
    display: block;
    background-size: 100%;
    background-image: url(${HighFiveIcon});
    background-repeat: no-repeat;
    height: 35px;
    width: 35px;
    text-align: left;
    margin-right: 15px;
  }

  @media ${device.tablet} {
    font-size: 1.25rem;
  }
`;

export const Section = styled.div`
  /* display:flex; */
  /* justify-content:center; */
  /* flex-wrap:wrap; */
  /* flex-direction:column; */
  border-top: 1px solid rgba(151, 151, 151, 0.5);
  padding-bottom: 40px;
`;

export const SectionHeading = styled.h3`
  font-size: 0.875rem;
  color: #000000;
  width: 100%;
  text-shadow: 0 0 44px rgba(0, 0, 0, 0.5);
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  @media ${device.tablet} {
    font-size: 1.25rem;
    color: #3d434f;
    letter-spacing: -1.2px;
    line-height: 1;
  }

  span {
    font-weight: normal;
    margin-left: 10px;
  }
`;

export const SectionIcon = styled.img`
  max-width: 100%;
  margin-right: 15px;
  height: auto;
`;

export const NormalText = styled.span`
  font-weight: normal;
`;

export const ButtonWrapper = styled.div`
  display: flex;

  justify-content: center;
  text-align: center;

  padding-bottom: 40px;
  padding-top: 40px;
  * {
  }
`;

export const Wrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

export const Heading = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
  /* padding-top:20px; */
  text-align: left;
  color: #621b46;
  width: 100%;
`;

export const ResultsText = styled.div`
  width: 100%;
  padding-top: 30px;
  text-align: center;
  @media ${device.tablet} {
    width: 200px;
    text-align: left;
    padding-top: 0;
  }
`;

export const ResultsTextContent = styled.div`
  font-size: 0.875rem;
  color: #898989;
  font-weight: bold;
`;
export const ResultsTextHeading = styled.h3`
  color: #008067;
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

export const ShareButtonWrapper = styled.div`
  width: 130px;
`;
