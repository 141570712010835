import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  StyledSelectTags,
  SelectedOption,
  Options,
  Option,
  Selected,
} from "./SelectTags.style";
import { useTranslation } from "react-i18next";

const SelectTags = ({ options, onSelectCallback, ...rest }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const { t } = useTranslation();
  const ref = useRef(null);

  useEffect(() => {
    const callback = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setMenuVisible(false);
      }
    };

    document.addEventListener("mousedown", callback);

    return () => {
      document.removeEventListener("mousedown", callback);
    };
  }, []);

  const handleShowMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleOnSelect = (e) => {
    setSelectedItem(
      options.find(
        (item) => item.value === parseInt(e.currentTarget.dataset.value)
      )
    );
    onSelectCallback(e.currentTarget.dataset.value);
    setMenuVisible(false);
  };

  return (
    <StyledSelectTags ref={ref} {...rest}>
      <Selected onClick={handleShowMenu} isActive={menuVisible}>
        {!selectedItem
          ? t("results.filter_options.all_matches")
          : selectedItem.label}
      </Selected>
      <Options visible={menuVisible}>
        {options.map((item) => (
          <Option
            key={item.value}
            onClick={handleOnSelect}
            data-value={item.value}
            selected={item.selected}
          >
            {item.label}
          </Option>
        ))}
      </Options>
    </StyledSelectTags>
  );
};

SelectTags.propTypes = {
  options: PropTypes.array,
  selectedValue: PropTypes.string,
};

SelectTags.defaultProps = {};

export default SelectTags;
