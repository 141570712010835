import styled from "styled-components/macro";
import { device } from "../../theme";
import NoticeImage from "./img/warning.png";

export const Heading = styled.h2`
  text-align: center;
  color: #621b46;
  font-size: 20px;
  text-wrap: normal;
`;

export const Link = styled.a`
  color: ${(props) => (props.color === "green" ? "#01C4A6" : "#39CAE0")};
  font-size: 1em;
`;

export const Notice = styled.div`
  font-size: 0.875rem;
  background-color: rgba(0, 116, 102, 0.1);
  padding: 10px;
  margin-bottom: 20px;
  padding-left: 20px;
  color: #525252;
  font-size: 14px;
  text-align: left;
  display: flex;
  align-items: center;

  &::before {
    content: "";
    display: block;
    width: 21px;
    height: 21px;
    margin-right: 10px;
    background-image: url(${NoticeImage});
    background-repeat: no-repeat;
    background-position: center center;
  }
`;

export const CodeInput = styled.input`
  background-color: #f3f3f3;
  border: 1px solid #d1d3d4;
  color: #e51237;
  font-size: 0.875rem;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  margin-bottom: 20px;
`;
export const CodeInputLabel = styled.label`
  display: block;
  background-color: #007466;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #fff;
  font-size: 0.875rem;
  font-weight: bold;
`;
export const NoticeHeading = styled.div`
  font-weight: bold;
  font-size: 0.875rem;
  color: #525252;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 20px;
`;

export const NicknameWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const ProgressWrapper = styled.div`
  max-width: 710px;
  margin: 0 auto;
  padding-bottom: 40px;

  padding-top: 20px;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

export const LoadingWrapper = styled.div`
  margin-bottom: 20px;
`;

export const Content = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;

  @media ${device.tablet} {
    padding-left: 0;
    padding-right: 0;
  }
  text-align: center;
`;

export const Center = styled.div`
  margin: 0 auto;

  display: flex;
  justify-content: center;
`;

export const LargeText = styled.div`
  font-size: 1.2rem;
  font-weight: 700;

  @media ${device.tablet} {
    font-size: 1.5rem;
    line-height: 1.875;
  }
`;
export const Highlight = styled.span`
  color: #39cae0;
`;

export const Code = styled.a`
  padding: 4px;
  position: relative;

  cursor: pointer;

  &:active,
  &:hover {
    background-color: #39cae0;
    color: #fff;
    padding: 3px;
    border: 1px dashed #000;
  }

  &::before {
    position: absolute;
    bottom: -30px;
    width: 100px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    content: "Copied to clipboard";
    color: #fff;
    font-size: 0.6rem;
    padding: 5px;
    transition: all 0.4s;
    pointer-events: none;
    opacity: ${(props) => (props.showMessage ? "1" : "0")};
  }
`;
