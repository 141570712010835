import { useEffect, useState } from 'react';

const useMatchMedia = (breakpoint) => {

    const [matched, setMatched] = useState(false);

    useEffect(() => {
        const mediaQueryList = window.matchMedia(breakpoint);

        const callbackFn = (e) => {
            setMatched(e.matches);
        }
        

        setMatched(mediaQueryList.matches);

        // Handle safari and IE11
        if (typeof mediaQueryList.addEventListener === 'undefined') {
            mediaQueryList.addListener(callbackFn);
        } else {
            mediaQueryList.addEventListener("change", callbackFn);
        }

        return () => {
            if (typeof mediaQueryList.addEventListener === 'undefined') {
                mediaQueryList.removeListener(callbackFn);
            } else {
                mediaQueryList.removeEventListener("change", callbackFn);
            }

        }
    }, [breakpoint]);


    return [
        matched
    ]
}
export default useMatchMedia;