import React, {
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from "react";

import {
	ProgressWrapper,
	CardList,
	CardWrapper,
	WaitingWrapper,
	WaitingText,
	Heading,
	Highlight,
	Summary,
} from "./Questions.style";

import { DispatchContext, StateContext } from "../../reducer";
import Progress from "../../components/Progress";
import Container from "../../components/Container";
import Loader from "../../components/Loader";
import Card from "../../components/Card";
import { useSession } from "../../useSession";

/**
 *
 */
const Questions = () => {
	const state = useContext(StateContext);
	const dispatch = useContext(DispatchContext);


	const intervalRef = useRef(null);

	const questionRefs = useRef([]);

	const [setCode, startSession, joinSession, logAnswers] = useSession();

	/**
	 * Invoked when all questions have been answered in this section.
	 */
	const proceedToNextSection = useCallback(() => {
		// Naughty side-effect should be handled differently.
		window.scrollTo({
			top: 0,

			behavior: "smooth",
		});

		dispatch({ type: "NEXT_SECTION", payload: false });
	}, [state.currentActiveSectionIndex]);

	useEffect(() => {
		
		// May not exist if it's a results page.
		if (state.sections[state.currentActiveSectionIndex]) {
			//dispatch({type: 'SET_HEADING', payload: state.sections[state.currentActiveSectionIndex].label});
			window.parent.postMessage('event~htm~Step_' + (parseInt(state.currentActiveSectionIndex) + 1), '*');
		}
	}, [state.currentActiveSectionIndex, state.sections]);

	/**
	 * Keep an eye on the other users question state.
	 *
	 */
	useEffect(() => {
		if (state.isWaitingForOtherUser) {
			const callbackFn = () => {
				fetch(
					`${process.env.REACT_APP_API_ENDPOINT}/GetSession?code=${state.code}`
				)
					.then((res) => res.json())
					.then((res) => {
						dispatch({ type: "SET_SESSION", payload: res });
					});
			};

			intervalRef.current = setInterval(callbackFn, 2000);
		}

		return () => {
			clearInterval(intervalRef.current);
		};
	}, [state.isWaitingForOtherUser, state.code]);

	/**
	 * Mainly for mobile - scroll to next question. useLayoutEffect
	 * is used here to ensure theres no jump and the scroll is immediate.
	 */
	useLayoutEffect(() => {
		if (questionRefs.current[state.question]) {
			// questionRefs.current[state.question].scrollIntoView({
			//     behavior: 'smooth'
			//   });
		}
	}, [state.currentActiveSectionIndex, state.question]);

	// Invoked when you move onto the next step.
	useEffect(() => {
		const totalSections = Object.keys(state.sections).length;

		if (state.currentActiveSectionIndex >= totalSections) {
			dispatch({ type: "SET_PAGE", payload: "RESULTS" });
		}

		if (
			state.sectionIsComplete &&
			state.isWaitingForOtherUser &&
			state.serverActiveSectionIndex >= state.currentActiveSectionIndex
		) {
			proceedToNextSection();
		}
	}, [
		state.currentActiveSectionIndex,
		state.sectionIsComplete,
		state.serverActiveSectionIndex,
		state.yourSectionIndex,
		proceedToNextSection,
		state.isWaitingForOtherUser,
		state.sections,
		dispatch,
	]);

	/**
	 * This method will save all the users answers to the server.
	 */
	const saveAllAnswersToServer = () => {
		console.log(
			"save all answers to server ",
			state.currentActiveSectionIndex
		);

		fetch(process.env.REACT_APP_API_ENDPOINT + "/LogAnswers", {
			headers: {
				"Content-Type": "application/json",
			},
			method: "post",
			body: JSON.stringify({
				answers: state.yourAnswers,
				code: state.code,
				player: state.playerId,
				section: state.currentActiveSectionIndex,
			}),
		})
			.then((res) => res.json())
			.then((res) => {
				// Delegate this to the useEffect to check.
				dispatch({ type: "SET_WAITING_FOR_ANSWER", payload: true });
				dispatch({ type: "SET_SECTION_COMPLETE", payload: true });
			});
	};

	useEffect(() => {
		if (state.sections[state.currentActiveSectionIndex]) {
			const totalQuestionsForSection =
				state.sections[state.currentActiveSectionIndex].questions
					.length - 1;
			// When we've reached the end...
			if (state.question > totalQuestionsForSection) {
				saveAllAnswersToServer();
			}
		}
	}, [state.question, state.currentActiveSectionIndex, state.sections]);

	/**
	 * Write the answer to global store.
	 * @param {*} answer
	 * @param {*} event
	 */
	const handleAnswer = (answer, event) => {
		// Set answer to question.
		dispatch({ type: "SET_ANSWER", payload: answer });
	};

	const toAnswerIndex = state.currentActiveSectionIndex;

	// Based on the section - what questions should be active?
	const activeQuestions =
		state.sections[toAnswerIndex] &&
		Object.keys(state.sections[toAnswerIndex]).length > 0
			? state.sections[toAnswerIndex].questions
			: null;

	let options = [
		{ value: "y", icon: "tick", color: "#007466", filled: false },
		{ value: "n", icon: "cross", color: "#007466", filled: false },
		{ value: "m", icon: "unsure", color: "#007466", filled: false },
	];

	const section = state.sections[state.currentActiveSectionIndex];

	if (!section) {
		return <div>Loading...</div>;
	}

	return (
		<Container>
			<ProgressWrapper>
				<Progress
					questions={state.sections}
					activeSection={toAnswerIndex}
				/>
			</ProgressWrapper>

			<Heading>{section.label}</Heading>
			<Summary dangerouslySetInnerHTML={{ __html: section.summary }} />
			<CardList>
				{activeQuestions &&
					activeQuestions.map((question, index) => {
						let answers = options.map((item) => {
							let answer = { ...item };
							if (
								state.yourAnswers[toAnswerIndex] &&
								state.yourAnswers[toAnswerIndex][index] ===
									item.value
							) {
								answer.filled = true;
							}
							return answer;
						});

						return (
							<CardWrapper
								ref={(ref) =>
									(questionRefs.current[index] = ref)
								}
								key={toAnswerIndex + "_" + index}>
								<Card
									disabled={state.question !== index}
									heading={question.label}
									answerCallback={handleAnswer}
									answers={answers}
								/>
							</CardWrapper>
						);
					})}
			</CardList>

			{state.isWaitingForOtherUser && (
				<WaitingWrapper>
					<Loader />
					<WaitingText>
						Waiting for {state.theirNickname} to answer...
					</WaitingText>
				</WaitingWrapper>
			)}
		</Container>
	);
};

export default Questions;
