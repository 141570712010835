import React, { useContext, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import UserHeader from "../../components/UserHeader";

import PlayerOne from "../../assets/user1.png";
import PlayerTwo from "../../assets/user2.png";

import {
  ButtonWrapper,
  Container,
  Heading,
  NicknameWrapper,
  Notice,
  ErrorMessage,
  ColumnTop,
  Form,
  ColumnWrapper,
  Introduction,
  ColumnClean,
} from "./Index.style";
import Instruction from "../../components/Instruction";
import Button from "../../components/Button";

import { DispatchContext, StateContext } from "../../reducer";
import TextField from "../../components/TextField";
import { useSession } from "../../useSession";
import Column from "../../components/Column/Column";

const Index = () => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const { t } = useTranslation();

  // Local state, as this is only relevant for this page.
  const [step, setStep] = useState(null);
  const [nickname, setNickname] = useState("");
  const [player2Nickname, setPlayer2Nickname] = useState("");

  const [setCode, startSession, joinSession] = useSession();

  const handleJoinSession = () => {
    setStep("JOIN_SESSION");
  };

  const handleStartOver = () => {
    setStep(null);
    setNickname("");
    setPlayer2Nickname("");
    setCode(null);
  };

  /**
   * When they click "Start new."
   */
  const handleNewSession = () => {
    setStep("START_SESSION");
  };

  const handleChangeNickname = (e) => {
    if (step === "JOIN_SESSION") {
      setPlayer2Nickname(e.currentTarget.value);
    } else {
      setNickname(e.currentTarget.value);
    }
  };

  const handleChangeCode = (e) => {
    setCode(e.currentTarget.value.trim());
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();

    if (
      (step === "START_SESSION" && !nickname) ||
      (step === "JOIN_SESSION" && !player2Nickname)
    ) {
      dispatch({ type: "SET_ERROR", payload: t("index.error_enter_nickname") });
      return;
    }

    if (step === "JOIN_SESSION") {
      joinSession(player2Nickname);
    }

    if (step === "START_SESSION") {
      startSession(nickname);
    }
  };

  return (
    <Container>
      <Heading>{t("index.heading")}</Heading>
      {!step && (
        <Introduction>
          <p>{t("index.introduction")}</p>
          <p>{t("index.introduction_line_2")}</p>
        </Introduction>
      )}
      <Form onSubmit={handleFormSubmission}>
        <ColumnWrapper>
          {step === "START_SESSION" && (
            <ColumnClean>
              <ColumnTop>
                <UserHeader
                  name={t("global.player_1")}
                  session="(Join session)"
                  image={PlayerOne}
                />

                {step === "START_SESSION" && (
                  <NicknameWrapper>
                    <TextField
                      required
                      onChange={handleChangeNickname}
                      value={nickname}
                      placeholder={t("global.enter_nickname")}
                    />
                  </NicknameWrapper>
                )}
              </ColumnTop>
              <Button color="filled" size="full-width">
                {t("index.button_get_matching")}
              </Button>
            </ColumnClean>
          )}

          {step === "JOIN_SESSION" && (
            <ColumnClean>
              <ColumnTop>
                <UserHeader
                  name={nickname == "" ? t("global.player_2") : nickname}
                  session="(Join session)"
                  image={PlayerOne}
                />

                <NicknameWrapper>
                  <TextField
                    required
                    onChange={handleChangeNickname}
                    value={state.nickname}
                    placeholder={t("global.enter_nickname")}
                  />
                </NicknameWrapper>
                <NicknameWrapper>
                  <TextField
                    required
                    onChange={handleChangeCode}
                    value={state.code}
                    placeholder={t("global.enter_code")}
                  />
                </NicknameWrapper>
              </ColumnTop>
              {state.error && <ErrorMessage>{state.error} </ErrorMessage>}
              <Button color="filled">{t("index.button_join_session")}</Button>
            </ColumnClean>
          )}
          {!step && (
            <Column>
              <ColumnTop>
                <UserHeader
                  name={nickname == "" ? t("global.player_1") : nickname}
                  session="(Join session)"
                  image={PlayerOne}
                />

                <Instruction>
                  <Trans i18nKey="index.start_session">
                    If you’re the one who usually makes the decisions, click{" "}
                    <strong>Start</strong>
                  </Trans>
                </Instruction>
              </ColumnTop>

              {step !== "START_SESSION" && (
                <ButtonWrapper>
                  <Button
                    onClick={handleNewSession}
                    type="button"
                    size="full-width"
                    color="filled"
                  >
                    {t("index.button_start")}
                  </Button>
                </ButtonWrapper>
              )}
            </Column>
          )}

          {!step && (
            <Column>
              <ColumnTop>
                <UserHeader
                  name={
                    player2Nickname == ""
                      ? t("global.player_2")
                      : player2Nickname
                  }
                  session="(Join session)"
                  image={PlayerTwo}
                />

                <Instruction>
                  <Trans i18nKey="index.join_session">
                    If you’ve been given a code to join the boss, click{" "}
                    <strong>Join</strong>
                  </Trans>
                </Instruction>
              </ColumnTop>

              <ButtonWrapper>
                <Button
                  color="outline"
                  type="button"
                  size="full-width"
                  onClick={handleJoinSession}
                >
                  {t("index.button_join_session")}
                </Button>
              </ButtonWrapper>
            </Column>
          )}
        </ColumnWrapper>

        {step && (
          <ButtonWrapper>
            <Button type="button" color="outline" onClick={handleStartOver}>
              {t("index.button_start_over")}
            </Button>
          </ButtonWrapper>
        )}
      </Form>
      <Notice>
        <div>
          <Trans i18nKey="index.notice_two_devices">
            <strong>Please note</strong> - You will need two devices to play!
          </Trans>
        </div>
      </Notice>
    </Container>
  );
};

export default Index;
