import logo from "./logo.svg";
import Index from "./screens/Index/Index";
import { Wrapper } from "./App.style";
import "fontsource-open-sans";
import "fontsource-open-sans/700-normal.css";
import Banner from "./components/Banner";
import { createGlobalStyle } from "styled-components";
import { useEffect, useReducer } from "react";
import {
  reducer,
  StateContext,
  DispatchContext,
  initialState,
} from "./reducer";
import Lobby from "./screens/Lobby/Lobby";
import Questions from "./screens/Questions/Questions";
import Results from "./screens/Results/Results";
import "./i18n";
import { useTranslation } from "react-i18next";

const GlobalStyle = createGlobalStyle`
  body {
    padding:0;
    margin:0;
    color: ${(props) => (props.whiteColor ? "white" : "black")};
  }
`;
const urlParams = new URLSearchParams(window.location.search);
const code = urlParams.get("code");

const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { t, i18n } = useTranslation();

  // Load in config
  useEffect(() => {
    fetch(`/data/questions_${i18n.language}.json`)
      .then((res) => res.json())
      .then((res) => {
        dispatch({ type: "SET_SECTIONS", payload: res.sections });
      });
  }, []);

  // is there a code
  useEffect(() => {
    if (code) {
      dispatch({ type: "SET_CODE", payload: code });
      dispatch({ type: "SET_PAGE", payload: "RESULTS" });
    }
  }, []);

  const pages = {
    LOBBY: Lobby,
    INDEX: Index,
    QUESTIONS: Questions,
    RESULTS: Results,
  };

  // state.page
  const Page = pages[state.page];

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>
        <GlobalStyle />
        <Wrapper>
          <Banner heading={t("global.heading")} />
          <Page />
        </Wrapper>
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
};
export default App;
