import styled from 'styled-components/macro';




export const Wrapper = styled.div`



    * {
        box-sizing:border-box;
        font-family: 'Open Sans', sans-serif;
        -webkit-font-smoothing: antialiased;
    }
    strong {
        font-weight:700;
    }
`;
