import React from 'react';
import { 
    StyledColumn
} from './Column.style';



const Column = ({ children, disabled }) => {


    return (
        <StyledColumn disabled={ disabled }>
           { children }
        </StyledColumn>
    );
}

export default Column;