import React from "react";
import PropTypes from "prop-types";
import { StyledHeader, Wrapper, Logo, Image, Text } from "./Banner.style";

const Banner = ({ heading }) => {
  return (
    <StyledHeader>
      <Wrapper>
        {/* <Logo><Image src={ LogoImage } /></Logo> */}

        <Text>{heading}</Text>
      </Wrapper>
    </StyledHeader>
  );
};

Banner.propTypes = {
  heading: PropTypes.string,
};

export default Banner;
