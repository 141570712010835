import React, { useContext, useEffect, useLayoutEffect, useRef } from "react";

import {
	CardList,
	CardWrapper,
	Header,
	PieWrapper,
	SummaryWrapper,
	FilterWrapper,
	Heading,
	ResultsHeading,
	Section,
	SectionHeading,
	ResultsHeader,
	FilterLabel,
	ButtonWrapper,
	NormalText,
	Wrapper,
	SectionIcon,
	ResultsText,
	ResultsTextContent,
	ResultsTextHeading,
	ShareButtonWrapper,
} from "./Results.style";

import { Doughnut, Chart } from "react-chartjs-2";

import { DispatchContext, StateContext } from "../../reducer";
import Container from "../../components/Container";
import Card from "../../components/Card";
import SelectTags from "../../components/SelectTags";
import Button from "../../components/Button";
import { Trans, useTranslation } from "react-i18next";
import useMatchMedia from "../../useMatchMedia";

function roundToNearest20(number, increment, offset) {
	return Math.ceil((number - offset) / increment) * increment + offset;
}
/**
 * Shows the results page - this can be loaded directly via the ?code param.
 */
const Results = () => {
	const state = useContext(StateContext);
	const dispatch = useContext(DispatchContext);
	const { t, i18n } = useTranslation();
	const [isLargerScreen] = useMatchMedia("(min-width:768px)");
	/**
	 * Keep an eye on the other users question state.
	 *
	 */
	useEffect(() => {
		// dispatch({type: 'SET_HEADING', payload: 'Results'});

		fetch(
			`${process.env.REACT_APP_API_ENDPOINT}/GetSession?code=${state.code}`
		)
			.then((res) => res.json())
			.then((res) => {
				dispatch({ type: "SET_PLAYER", payload: 1 });
				dispatch({ type: "SET_SESSION", payload: res });
			})
			.catch((err) => {
				window.location = "/" + i18n.language;
			});
	}, [state.code, dispatch, i18n.language]);

	useEffect(() => {
		// eslint-disable-next-line no-restricted-globals
		const url = `${location.protocol}//${location.host}${location.pathname}?code=${state.code}`;

		// eslint-disable-next-line no-restricted-globals
		history.pushState({}, "Url", url);
	}, [state.code]);


	// Tracking.
	useEffect(() => {
		window.parent.postMessage('event~htm~Step_7', '*');
	}, []);

	const calculateTotalPercentage = () => {
		let total = 0;
		for (let i = 0; i < state.sections.length; i++) {
			let percent = calculateSectionPercentage(i);
			total += percent;
		}
		return total / state.sections.length;
	};

	/**
	 *
	 * @param {*} sectionIndex
	 */
	const getTopLevelBreakdown = () => {
		let agreed = 0;
		let disagreed = 0;
		let unsure = 0;
		state.sections.map((section, sectionIndex) => {
			const yourAnswers = state.yourAnswers[sectionIndex];
			const theirAnswers = state.theirAnswers[sectionIndex];

			section.questions.map((item, index) => {
				if (
					yourAnswers[index] === theirAnswers[index] &&
					theirAnswers[index] === "n"
				) {
					unsure++;
				} else if (yourAnswers[index] === theirAnswers[index]) {
					agreed++;
				} else if (yourAnswers[index] !== theirAnswers[index]) {
					disagreed++;
				}
			});
		});

		return {
			agreed,
			disagreed,
			unsure,
		};
	};

	// So we're looking to see if the other player has joined!
	useLayoutEffect(() => {
		if (
			!Object.keys(state.yourAnswers).length |
			!Object.keys(state.theirAnswers).length
		) {
			return;
		}

		const plugin = Chart.pluginService.register({
			beforeDraw: function (chart, easing) {
				// Plugin code.
				const width = chart.chart.width,
					height = chart.chart.height,
					ctx = chart.chart.ctx;
				var legendWidth = chart.legend.width / 2;
				var legendHeight = chart.legend.height / 2;

				ctx.restore();

				const offset = i18n.language === "cy" ? 20 : 30;
				let currentY = height / 2 - offset - legendHeight;

				// Add "You are both in."
				let text = t("results.chart.text_you_are_both");

				// Drop in the
				let fontSize = (height / 250).toFixed(2);
				ctx.font = fontSize + "em Open Sans";
				ctx.textBaseline = "middle";
				ctx.fillStyle = "#000";

				let textX;
				let lines = text.split("\n");
				for (const line of lines) {
					textX = Math.round(
						(width - ctx.measureText(line).width) / 2
					);

					if (line.includes("[PERCENTAGE]")) {
						const percentage = calculateTotalPercentage();

						// Actual percentage.
						text = Math.round(percentage) + "%";

						// Create percentage. -----
						fontSize = (height / 90).toFixed(2);
						ctx.font = "bold " + fontSize + "em Open Sans";
						ctx.textBaseline = "middle";
						textX = Math.round(
							(width - ctx.measureText(text).width) / 2
						);

						currentY += 20;
						ctx.fillStyle = "#008067";
						ctx.fillText(text, textX, currentY);
						currentY += 30;
					} else {
						fontSize = (height / 250).toFixed(2);
						ctx.font = fontSize + "em Open Sans";
						ctx.textBaseline = "middle";
						ctx.fillStyle = "#000";
						textX = Math.round(
							(width - ctx.measureText(line).width) / 2
						);

						ctx.fillText(line, textX, currentY);

						currentY += 20;
					}
				}

				ctx.save();
			},
		});

		return () => {
			Chart.pluginService.unregister(plugin);
		};
	}, [state.yourAnswers, state.theirAnswers, state.sections]);

	/**
	 * Simple helper to calculate percentage.
	 * @param {*} section
	 */
	const calculateSectionPercentage = (section) => {
		let totalQuestions = Object.keys(state.sections[section]).length - 1;
		let agreed = 0;

		state.sections[section].questions.map((item, index) => {
			if (
				state.yourAnswers[section][index] ===
				state.theirAnswers[section][index]
			) {
				agreed++;
			}
		});

		return (agreed / totalQuestions) * 100;
	};

	/**
	 * Simple helper to calculate matches per section
	 * @param {*} section
	 */
	const calculateMatchesPerSectionBreakdown = (section) => {
		let breakdown = { agreed: 0, disagreed: 0, unsure: 0 };

		state.sections[section].questions.map((item, index) => {
			if (
				state.yourAnswers[section][index] === "m" ||
				state.theirAnswers[section][index] === "m"
			) {
				breakdown["unsure"]++;
			} else if (
				state.yourAnswers[section][index] ===
				state.theirAnswers[section][index]
			) {
				breakdown["agreed"]++;
			} else {
				breakdown["disagreed"]++;
			}
		});

		return breakdown;
	};

	// Loading - TODO: change to spinner.
	if (
		!Object.keys(state.yourAnswers).length ||
		!Object.keys(state.theirAnswers).length
	) {
		return <div>Loading...</div>;
	}

	const breakdown = getTopLevelBreakdown();

	const data = {
		labels: [
			t("results.chart.agreed"),
			t("results.chart.disagreed"),
			t("results.chart.undecided"),
		],

		datasets: [
			{
				data: [breakdown.agreed, breakdown.disagreed, breakdown.unsure],
				backgroundColor: ["#008067", "#E51636", "#DDDDDD"],
				hoverBackgroundColor: ["#008067", "#B1B1B1", "#DDDDDD"],
			},
		],
	};

	const options = [
		{ value: "y", icon: "tick", color: "#01C4A6", filled: false },
		{ value: "n", icon: "cross", color: "#01C4A6", filled: false },
		{ value: "m", icon: "unsure", color: "#01C4A6", filled: false },
	];

	/**
	 * When the filter is clicked let's
	 */
	const handleSortOrderChange = (selected) => {
		dispatch({ type: "SET_SORT_ORDER", payload: selected });
	};

	const handleFilterBy = (selected) => {
		dispatch({ type: "SET_FILTER_BY", payload: selected });
	};

	// structure:
	// [{label: "x", }]
	let results = state.sections.map((section, sectionIndex) => {
		let questions = section.questions.map((question, questionIndex) => {
			const player1Answer =
				state.yourAnswers[sectionIndex][questionIndex];
			const player2Answer =
				state.theirAnswers[sectionIndex][questionIndex];

			let matched = false;
			let result = "disagree";
			if (player1Answer === player2Answer) {
				matched = true;
				result = "agree";
				if (player1Answer === "n") {
					result = "unsure";
				}
			}

			let answers = options.map((answer) => {
				let option = { ...answer };

				// Ok this is what they both picked
				if (matched && answer.value === player1Answer) {
					option.icon = "highfive";
					option.after = t("results.matched");
					option.afterColor = "#008067";
					option.color = "#008067";
				} else if (answer.value === player1Answer) {
					option.after = state.player1.nickname;
					option.color = "#EF444D";
				} else if (answer.value === player2Answer) {
					option.after = state.player2.nickname;
					option.color = "#EF444D";
				} else {
					option.disabled = true;
				}

				option.filled = true;

				return option;
			});

			return {
				label: question.label,
				result, // human readable.
				answers,
				matched,
			};
		});

		// Return the section.
		return {
			label: section.label,
			image: section.image,
			percentageAgreed: calculateSectionPercentage(sectionIndex),
			breakdown: calculateMatchesPerSectionBreakdown(sectionIndex),
			questions: questions,
		};
	});

	let filteredResults = [...results];

	// Handle sort logic before rendering.
	if (state.filterBy) {
		const filterById = parseInt(state.filterBy);

		filteredResults = filteredResults.map((result) => {
			let questions = result.questions.filter((answer) => {
				return (
					(filterById === 1 && answer.result === "agree") ||
					(filterById === 2 && answer.result === "disagree") ||
					(filterById === 3 && answer.result === "unsure") ||
					!filterById
				);
			});
			return { ...result, questions };
		});

		filteredResults = filteredResults.sort((a, b) => {
			return b.questions.length - a.questions.length;
		});
	}

	const percentageAgreed = roundToNearest20(
		calculateTotalPercentage(),
		20,
		0
	);

	const getShareUrl = () => {
		// eslint-disable-next-line no-restricted-globals
		return t("results.share_link");
		// return "https://www.principality.co.uk/mortgages/House-Taste-Matcher";
	};

	const handleShareFacebook = () => {
		window.FB.ui(
			{
				method: "share",
				href: getShareUrl(),
				quote: t("location.facebookShareText"),
			},
			function (response) {}
		);
		window.parent.postMessage("event~htm~Share", '*');
	};

	return (
		<>
			<Container>
				<Header>
					<SummaryWrapper>
						{!isLargerScreen && (
							<Heading
								style={{
									textAlign: "center",
									paddingBottom: "20px",
								}}>
								{t("results.heading")}
							</Heading>
						)}
						<PieWrapper>
							<div
								style={{
									position: "relative",
									height: "100%",
									width: "100%",
								}}>
								<Doughnut
									data={data}
									options={{
										maintainAspectRatio: false,
										cutoutPercentage: 80,
										legend: {
											position: "bottom",
											labels: {
												fontColor: "#000",
												boxWidth: 15,
											},
										},
										plugins: {
											labels: {
												fontSize: 12,

												fontColor: "#fff",

												position: "bottom",

												overlap: true,
											},
										},
									}}
								/>
							</div>
						</PieWrapper>
						<ResultsText>
							{isLargerScreen && (
								<Heading>{t("results.heading")}</Heading>
							)}
							<ResultsTextHeading>
								{t(
									`results.summary.${percentageAgreed}.heading`
								)}
							</ResultsTextHeading>
							<ResultsTextContent>
								{t(
									`results.summary.${percentageAgreed}.content`
								)}
							</ResultsTextContent>
						</ResultsText>
					</SummaryWrapper>
				</Header>
			</Container>
			<Wrapper>
				<Container>
					<ResultsHeader>
						<ResultsHeading>
							{t("results.compare_heading")}
						</ResultsHeading>
						<ShareButtonWrapper>
							<Button
								color='filled'
								size='large'
								onClick={handleShareFacebook}>
								{t("results.share_this")}
							</Button>
						</ShareButtonWrapper>
						<FilterWrapper>
							<FilterLabel>{t("results.filter_by")}</FilterLabel>
							<SelectTags
								onSelectCallback={handleFilterBy}
								options={[
									{
										label: t(
											"results.filter_options.mostly_agreed"
										),
										value: 1,
										selected:
											parseInt(state.filterBy) === 1
												? true
												: false,
									},
									{
										label: t(
											"results.filter_options.mostly_disagreed"
										),
										value: 2,
										selected:
											parseInt(state.filterBy) === 2
												? true
												: false,
									},
									{
										label: t(
											"results.filter_options.mostly_unsure"
										),
										value: 3,
										selected:
											parseInt(state.filterBy) === 3
												? true
												: false,
									},
									{
										label: t(
											"results.filter_options.all_matches"
										),
										value: null,
										selected: !parseInt(state.filterBy)
											? true
											: false,
									},
								]}
							/>
						</FilterWrapper>
					</ResultsHeader>

					{filteredResults.map((section, sectionIndex) => {
						let { percentageAgreed } = section;
						percentageAgreed = Math.round(percentageAgreed);
						if (!section.questions) {
							return <></>;
						}
						return (
							<Section key={sectionIndex}>
								<SectionHeading>
									<SectionIcon
										src={section.image}
										alt={section.label}
									/>
									{section.label}{" "}
									{!state.filterBy && (
										<NormalText>
											<Trans i18nKey='results.section_heading'>
												({{ percentageAgreed }}% in
												agreement)
											</Trans>
										</NormalText>
									)}
								</SectionHeading>
								<CardList>
									{section.questions.map(
										(question, questionIndex) => {
											return (
												<CardWrapper
													key={questionIndex}>
													<Card
														heading={question.label}
														answers={
															question.answers
														}
													/>
												</CardWrapper>
											);
										}
									)}
								</CardList>
							</Section>
						);
					})}

					<ButtonWrapper>
						<Button
							color='filled'
							size='large'
							onClick={handleShareFacebook}>
							{t("results.share_this")}
						</Button>
					</ButtonWrapper>
				</Container>
			</Wrapper>
		</>
	);
};

export default Results;
