import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
const resources = {
  en: {
    translation: {
      index: {
        start_session:
          "If you’re the one who usually makes the decisions, click <2>Start</2>",
        join_session:
          "If you’ve been given a code to join the boss, click <2>Join</2>",
        heading: "Enter your details",
        introduction:
          "It can be hard enough agreeing what takeaway to have, let alone choosing a house together. So, to take some of the stress out of picking a house, we’ve created this taste matching game to help you find your perfect match.",
        introduction_line_2:
          "Sit back, relax, click Start or Join to get matching, and no peeking at each other’s answers.",
        notice_two_devices:
          "<0>Please note:</0> You will need two devices to play!",
        button_start: "Start",
        button_join: "Join",
        button_start_over: "Start over",
        button_get_matching: "Lets get matching!",
        button_join_session: "Join",
        enter_nickname: "Enter nickname",
      },

      lobby: {
        waiting_for_player: "Waiting for Player two to join!",
        share_code: "Don’t forget to share your code with Player two:",
        click_to_copy: "Click to copy code",
        copied: "Copied to clipboard!",
      },

      results: {
        share_link: "https://www.principality.co.uk/first-home-steps/Finding-your-first-home/House-Taste-Matcher",
        share_this: "Share this",
        matched: "Match!",
        heading: "Your results",
        compare_heading: "Compare your answers",
        filter_by: "Filter by:",
        pick_one: "Pick one",
        filter_options: {
          mostly_agreed: "Mostly Agreed",
          mostly_disagreed: "Mostly Disagreed",
          mostly_unsure: "Mostly Unsure",
          all_matches: "All Matches",
        },

        chart: {
          agreed: "Agreed",
          disagreed: "Disagreed",
          undecided: "Undecided",
          text_you_are_both: "You are both:\n[PERCENTAGE]\nin agreement",
          text_in_agreement: "in agreement",
        },

        summary: {
          20: {
            heading: "0 – 20%",
            content:
              "So, it seems you don’t agree on everything when it comes to your taste in houses - but everyone has to start somewhere! Why not have a chat about what you’re both looking for in a home and then play the game again?",
          },
          40: {
            heading: "21-40%",
            content:
              "You do agree on some things! Not everything, but it’s a start. This is a good chance for you to talk about what you’d both like in a house and work out the best compromise.",
          },
          60: {
            heading: "41 – 60%",
            content:
              "There’s good news, and bad news.  The good news is that you are compatible in your taste in houses. The bad news is that you’re going to have to make some compromises. ",
          },
          80: {
            heading: "61- 80%",
            content:
              "Hoorah! You both have similar taste when it comes to houses. There’s plenty that you agree on, and some things you don’t but it’s a great start! ",
          },
          100: {
            heading: "81-100%",
            content:
              "Two peas in a pod! Everyone’s taste is different, but it looks like you two are on the same page! Now you know what you both want, it’s time to start looking for your new home!",
          },
        },
        section_heading: "({{percentageAgreed}}% in agreement)",
        share_text: "Share",
      },
      global: {
        enter_nickname: "Enter nickname",
        enter_code: "Enter Code",
        player_1: "Player one",
        player_2: "Player two",

        heading: "House taste matcher",
      },
    },
  },
  cy: {
    translation: {
      index: {
        start_session:
          "Os mai chi yw'r un sydd fel arfer yn gwneud y penderfyniadau, cliciwch <2>Cychwyn</2>",
        join_session: "Os ydych wedi cael cod i ymuno, cliciwch <2>Ymuno</2>",
        heading: "Rhowch eich manylion",
        introduction:
          "Gall fod yn ddigon anodd cytuno pa decawê i'w gael heb sôn am ddewis tŷ gyda'ch gilydd. Felly, i leihau’r straen o ddewis tŷ, rydym wedi creu'r gêm paru chwaeth hon i'ch helpu i ddod o hyd i'ch tŷ perffaith.",
        introduction_line_2:
          "Eisteddwch yn ôl, ymlaciwch, a chliciwch Cychwyn neu Ymuno i ddechrau paru. A pheidiwch ag edrych ar atebion eich gilydd. ",
        notice_two_devices:
          "<0>Noder:</0> Bydd angen dwy ddyfais arnoch i chwarae!",
        button_start: "Cychwyn",
        button_join: "Ymuno",
        button_start_over: "Dechrau Eto",
        button_get_matching: "Gadewch inni baru!",
        button_join_session: "Ymuno",
        enter_nickname: "Nodwch lysenw",
      },

      lobby: {
        waiting_for_player: "Aros i Chwaraewr dau ymuno!",
        share_code: "Cofiwch rannu eich cod gyda Chwaraewr dau:",
        click_to_copy: "Cliciwch i gopïo cod",
        copied: "Cod wedi'i gopïo i'r clipfwrdd",
      },

      results: {
        share_link: "https://www.principality.co.uk/cy/first-home-steps/Finding-your-first-home/House-Taste-Matcher",
        share_this: "Rhannu hwn",
        matched: "Baru!",
        heading: "Eich canlyniadau",
        compare_heading: "Cymharwch eich atebion",
        filter_by: "Hidlo fesul:",
        filter_options: {
          mostly_agreed: "Cytuno",
          mostly_disagreed: "Anghytuno",
          mostly_unsure: "Heb benderfynu",
          all_matches: "Pob pâr",
        },

        chart: {
          agreed: "Cytuno",
          disagreed: "Anghytuno",
          undecided: "Heb benderfynu",
          text_you_are_both: "Mae'r ddau ohonoch\n yn cytuno:\n[PERCENTAGE]",
          text_in_agreement: "in agreement",
        },
        summary: {
          20: {
            heading: "0 – 20%",
            content:
              "Felly, mae'n ymddangos nad ydych chi'n cytuno ar bopeth o ran eich chwaeth mewn tai - ond mae'n rhaid i bawb ddechrau yn rhywle! Beth am gael sgwrs am yr hyn yr ydych chi ei eisiau mewn cartref ac yna chwarae'r gêm eto?",
          },
          40: {
            heading: "21-40%",
            content:
              "Rydych chi'n cytuno ar rai pethau! Nid popeth, ond mae'n fan cychwyn. Mae hwn yn gyfle da i chi siarad am yr hyn yr hoffech chi'ch dau mewn tŷ a dod i’r cyfaddawd gorau.",
          },
          60: {
            heading: "41 – 60%",
            content:
              "Mae newyddion da, a newyddion drwg.  Y newyddion da yw bod eich chwaeth mewn tai yn cyfateb. Y newyddion drwg yw y bydd yn rhaid i chi gyfaddawdu ychydig.",
          },
          80: {
            heading: "61- 80%",
            content:
              "Hwre! Mae y ddau ohonoch yn debyg o ran eich chwaeth mewn tai.  Mae digon yr ydych chi'n cytuno arno, a rhai pethau nad ydych chi'n cytuno arnyn nhw ond mae'n ddechrau gwych!",
          },
          100: {
            heading: "81-100%",
            content:
              "Tebyg at ei debyg! Mae chwaeth pawb yn wahanol, ond mae'n ymddangos eich bod chi'ch dau yn cytuno! Nawr eich bod chi'n gwybod beth mae'r ddau ohonoch ei eisiau, mae'n bryd dechrau chwilio am eich cartref newydd!",
          },
        },
        section_heading: "(yn cytuno {{percentageAgreed}}%)",
        share_text: "Rhannu",
      },
      global: {
        enter_nickname: "Nodwch lysenw",
        enter_code: "Nodwch y cod",
        player_1: "Chwaraewr Un",
        player_2: "Chwaraewr dau",

        heading: "Paru Chwaeth Tŷ",
      },
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    supportedLngs: ["en", "cy"],
    detection: {
      order: ["path"],
      lookupFromPathIndex: 0,
    },
    resources,
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
