import React from "react";
import { Content, StyledInstruction } from "./Instruction.style";

const Instruction = ({ image, children }) => {
  return (
    <StyledInstruction>
      <Content>{children}</Content>
    </StyledInstruction>
  );
};

export default Instruction;
