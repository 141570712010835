import styled from "styled-components/macro";

import CheckIcon from "./assets/check.png";

export const StyledProgress = styled.div``;

export const Items = styled.ol`
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const buildStyles = (props) => {
  if (props.active) {
    return `
            background-color: #3D434F;
            height:4px;
            border-radius:2px;
        `;
  }

  if (props.complete) {
    return `
            background-color: #000;
            height:2px;
            border-radius:2px;
        `;
  }

  return `
        background-color: #000;
        height:2px;
        border-radius:2px;
    `;
};

export const Image = styled.img`
  flex-grow: 1;
`;

export const Item = styled.li`
  margin-right: 30px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 38px;


  &::after {
    content: "";
    display: block;
    margin-top: 5px;
    ${(props) => buildStyles(props)}
    opacity: ${(props) => (props.complete ? 0.4 : 1)};
  }

  &::before {
    content: "";
    display: ${(props) => (props.complete ? "block" : "none")};
    position: absolute;
    top: 0px;
    left: 4px;
    background-image: url(${CheckIcon});
    height: 17px;
    width: 17px;
  }

  width: 80px;
  &:last-child {
    margin-right: 0;
  }

  ${Image} {
    opacity: ${(props) => (props.complete ? 0.4 : 1)};
  }
`;
