import styled from "styled-components/macro";

export const StyledUserHeader = styled.div`
  display: flex;
  text-align: left;
`;

export const UserHeaderImage = styled.img`
  align-self: center;
`;

export const Participant = styled.div`
  font-weight: bold;

  margin-bottom: 0;
  margin-top: 0;
  font-size: 14px;
  width: 100%;
  text-align: center;
  color: #007466;
`;

export const Session = styled.div`
  margin-top: 0;
  margin-left: 10px;
  font-size: 10px;
  padding: 0;
`;

export const Column = styled.div`
  flex-direction: column;
  margin: 0;
  padding: 0;
`;
