import styled from "styled-components/macro";

import HeaderImage from "./header.png";

import BackgroundImage from "./assets/new-banner.jpg";
import BackgroundImageMobile2x from "./assets/banner-mobile@2x.jpg";

import TopLeft from "./assets/large-top-left.png";
import LargeMiddle from "./assets/large-glass.png";
import LogoImage from "./assets/banner-logo.png";

import { device } from "../../theme";

export const StyledHeader = styled.div`
  background-image: url(${TopLeft}), url(${LargeMiddle}), url(${LogoImage});
  background-position: left 60px top, bottom center, right 20px top 20px;
  background-size: 84px, 150px, 55px;
  background-repeat: no-repeat;
  background-color: #621b46;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  padding-bottom: 30px;
  padding-left: 20px;
  min-height: 150px;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url(${TopLeft}), url(${LargeMiddle}), url(${LogoImage});
    background-size: 84px, 80px, 45px;
    background-position: left 60px top, bottom right, right 20px top 10px;
  }

  @media ${device.tablet} {
    min-height: 250px;
    background-image: url(${TopLeft}), url(${LargeMiddle}), url(${LogoImage});
    background-size: 84px, 200px, 55px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  position: relative;

  justify-content: flex-start;

  text-align: left;
  padding-left: 20px;
`;

export const Logo = styled.div`
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);

  width: 100px;

  @media ${device.tablet} {
    width: auto;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

export const Text = styled.h1`
  font-size: 1rem;
  color: #ffffff;
  letter-spacing: -1.2px;
  line-height: 1;
  padding-left: 30px;

  padding: 0;
  margin: 0;

  @media ${device.mobileM} {
    font-size: 1.2rem;
  }

  @media ${device.tablet} {
    font-size: 2.5rem;
    letter-spacing: -2.4px;
  }
`;
