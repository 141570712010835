import styled from "styled-components/macro";

const colors = {
  blue: "#39CAE0",
  green: "#01C4A6",
  grey: "#3D434F",

  filled: "#007466",
  outline: "transparent",
};

export const StyledButton = styled.button`
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: ${(props) =>
    props.color === "outline" ? "1px solid #979797" : "none"};
  width: ${(props) => (props.size !== "full-width" ? "auto" : "100%")};
  font-size: ${(props) => (props.size === "small" ? "12px" : "13px")};

  color: ${(props) => (props.color === "outline" ? "#007466" : "#fff")};
  font-weight: bold;
  text-align: center;
  cursor: pointer;

  background-color: ${(props) => colors[props.color]};

  transition: all 0.4s;
  &:hover {
    opacity: 0.8;
  }
`;
