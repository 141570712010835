import { useContext, useState } from "react";
import { DispatchContext, StateContext } from "./reducer";

export const useSession = () => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const setCode = (code) => {
    dispatch({ type: "SET_CODE", payload: code });
  };

  /**
   * When they click 'lets get matching'
   */
  const handleStart = (nickname) => {
    // TODO add fetch in here.

    dispatch({ type: "SET_NEW_GAME", payload: true });
    dispatch({ type: "SET_LOADING", payload: true });

    // Only player1 creates games from scratch
    dispatch({ type: "SET_PLAYER", payload: 1 });

    fetch(process.env.REACT_APP_API_ENDPOINT + "/CreateSession", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        nickname,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        dispatch({ type: "SET_CODE", payload: res.code });
        dispatch({ type: "SET_PAGE", payload: "LOBBY" });
        dispatch({ type: "SET_LOADING", payload: false });
      });
  };

  // Person JOINING a lobby.
  const handleJoin = (nickname) => {
    dispatch({ type: "SET_PLAYER", payload: 2 });

    fetch(process.env.REACT_APP_API_ENDPOINT + "/JoinSession", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code: state.code,
        player: 2,
        nickname: nickname,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Invalid session");
        }
        return res.json();
      })
      .then((res) => {
        if (res && !res.error) {
          dispatch({ type: "SET_ERROR", payload: false });
          dispatch({ type: "SET_PAGE", payload: "QUESTIONS" });
        } else {
          dispatch({ type: "SET_ERROR", payload: "This is an invalid code." });
        }
      })
      .catch((err) => {
        dispatch({ type: "SET_ERROR", payload: "This is an invalid code." });
      });
  };

  return [setCode, handleStart, handleJoin];
};
