import styled from "styled-components/macro";
import { device } from "../../theme";

export const StyledColumn = styled.div`
  opacity: ${(props) => (props.disabled ? ".2" : 1)};
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #979797;
  padding: 30px;
  margin-bottom: 20px;

  @media ${device.tablet} {
    width: calc(100% / 2 - 20px);
    /* display:block; */
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
