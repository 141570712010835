import React from "react";
import PropTypes from "prop-types";
import { StyledButton } from "./Button.style";

const Button = ({ children, size, color, ...rest }) => {
  return (
    <StyledButton size={size} color={color} {...rest}>
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  color: PropTypes.oneOf(["blue", "green", "grey", "filled", "outline"]),
  size: PropTypes.oneOf(["full-width", "small", "default", "large"]),
};

Button.defaultProps = {
  color: "green",
  size: "default",
};

export default Button;
