import styled from "styled-components/macro";
import { device } from "../../theme";

export const ProgressWrapper = styled.div`
  max-width: 710px;
  margin: 0 auto;
  padding-bottom: 40px;

  padding-top: 20px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-left: 20px;
  margin-right: 20px;
  @media ${device.tablet} {
    margin-right: auto;
    margin-left: auto;
  }
`;

export const CardList = styled.div`
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  @media ${device.desktop} {
    justify-content: space-between;
  }
`;

export const CardWrapper = styled.div`
  max-width: 300px;
  display: flex;
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-right: 0;
  @media ${device.tablet} {
    padding-bottom: 0;
    margin-right: 20px;
  }
`;

export const WaitingWrapper = styled.div`
  display: flex;

  flex-wrap: wrap;

  padding-top: 50px;
  justify-content: center;
  padding-bottom: 50px;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  font-weight: bold;

  @media ${device.tablet} {
    position: relative;
    background-color: transparent;
    font-weight: normal;
    color: #000;
  }
`;

export const WaitingText = styled.div`
  padding-top: 20px;

  @media ${device.tablet} {
    padding-top: 0;
    padding-left: 20px;
  }
`;
export const Heading = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
  /* padding-top:20px; */
  padding-bottom: 0px;
  text-align: center;
  color: #621b46;
`;
export const Summary = styled.div`
  padding-top: 20px;
  max-width: 630px;
  margin: 0 auto;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
  font-size: 0.75rem;
  color: #525252;
  @media ${device.tablet} {
    padding-right: 0;
    padding-left: 0;
  }
`;

export const Highlight = styled.span`
  color: #01c4a6;
`;
