import styled from "styled-components/macro";
import { device } from "../../theme";
import NoticeImage from "./img/warning.png";

export const Heading = styled.h2`
  text-align: center;
  color: #621b46;
  font-size: 20px;
  text-wrap: normal;
`;

export const Link = styled.a`
  color: ${(props) => (props.color === "green" ? "#01C4A6" : "#39CAE0")};
  font-size: 1em;
  text-decoration: none;
`;

export const Notice = styled.div`
  font-size: 14px;
  background-color: rgba(0, 116, 102, 0.1);
  padding: 10px;
  margin-bottom: 20px;
  padding-left: 20px;
  max-width: 408px;
  margin: 0 auto;
  font-size: 14px;
  text-align: left;
  display: flex;
  align-items: center;
  margin-top: 20px;
  &::before {
    content: "";
    display: block;
    width: 21px;
    height: 21px;
    margin-right: 10px;
    background-image: url(${NoticeImage});
    background-repeat: no-repeat;
    background-position: center center;
  }
`;

export const ColumnClean = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin-bottom: 20px;
  max-width: 285px;
  margin-bottom: 0;
  padding-bottom: 0;
`;

export const ColumnTop = styled.div`
  flex-grow: 1;
`;
export const ButtonWrapper = styled.div`
  flex-wrap: wrap;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 20px;
`;

export const NicknameWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const Container = styled.div`
  margin: 0 auto;
  margin-left: 20px;
  margin-right: 20px;

  @media ${device.tablet} {
    max-width: 710px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  font-weight: 700;
  font-size: 1.1rem;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
  width: 100%;
  display: block;
`;

export const Form = styled.form``;

export const StartOverButton = styled.button`
  background-color: transparent;
  color: rgba(0, 0, 0, 0.9);
  font-size: 0.6875rem;
  font-weight: bold;
  text-decoration: underline;
  text-align: center;
  margin: 0 auto;
  border: none;
  cursor: pointer;
  display: block;
  margin-top: 40px;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @media ${device.tablet} {
    align-items: stretch;
  }
`;
export const Introduction = styled.div`
  font-size: 14px;
  text-align: center;
  color: #525252;
  & p:nth-child(2) {
    font-weight: bold;
  }
`;
