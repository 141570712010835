import React from 'react';
import { 
    StyledContainer
} from './Container.style';


const Container = ({ children }) => {


    return (
        <StyledContainer>
            { children }
        </StyledContainer>
    );
}

export default Container;