import React from "react";
import PropTypes from "prop-types";

const Cross = ({ color, filled, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="68"
      viewBox="0 0 68 68"
      {...rest}
    >
      <g fill={filled ? color : "none"} fillRule="evenodd">
        <g transform="translate(-601 -694)">
          <g transform="translate(601 694)">
            <circle
              cx="34"
              cy="34"
              r="33"
              stroke={color}
              strokeWidth="2"
            ></circle>
          </g>
        </g>
      </g>
      <path
        fill={filled ? "#fff" : color}
        strokeWidth="0.078"
        d="M25.159 46.564c0-3.748-.029-6.333-.07-6.29-.038.04-.354.775-.702 1.632-.736 1.815-.929 2.154-1.442 2.542-1.031.78-2.381.67-3.3-.265-.285-.292-.66-1.045-2.636-5.281-3.186-6.836-2.93-6.234-2.94-6.93-.007-.515.023-.65.247-1.106.716-1.455 2.401-1.899 3.733-.982.181.124.43.374.552.555.123.18.76 1.485 1.415 2.899s1.215 2.597 1.244 2.63c.03.033.643-1.387 1.364-3.155.72-1.768 1.4-3.384 1.511-3.592.428-.806 1.284-1.297 2.263-1.297 1.012 0 1.771.248 2.851.93 1.704 1.076 3.118 1.46 5.116 1.387 1.64-.06 2.794-.414 4.171-1.28 1.319-.829 1.91-1.033 2.997-1.035 1.186-.003 1.953.49 2.465 1.584.163.349.835 1.958 1.494 3.575.658 1.617 1.221 2.914 1.251 2.882.03-.032.59-1.215 1.244-2.63.654-1.413 1.29-2.714 1.415-2.89.252-.356.48-.543.997-.817.48-.255 1.5-.265 2.032-.02 1.054.486 1.721 1.768 1.478 2.842-.066.288-4.393 9.714-5.106 11.122-.101.2-.348.514-.548.699-.928.855-2.166.931-3.179.195-.516-.374-.717-.726-1.454-2.542-.353-.868-.673-1.61-.711-1.652-.042-.044-.07 2.51-.07 6.29v6.364H25.159z"
      ></path>
      <path
        fill={filled ? "#fff" : color}
        strokeWidth="0.078"
        d="M32.91 28.152c-2.64-.523-4.65-2.445-5.266-5.034-.137-.574-.158-.85-.126-1.698.044-1.21.163-1.688.674-2.726 1.118-2.27 3.287-3.622 5.808-3.62 1.25 0 2.232.26 3.243.858 1.45.855 2.503 2.195 3.03 3.853.263.832.304 2.415.085 3.333-.319 1.333-.959 2.423-1.98 3.373-.852.79-1.675 1.243-2.824 1.552-.563.151-2.11.215-2.644.109z"
      ></path>
    </svg>
  );
};

Cross.propTypes = {
  color: PropTypes.string,
  filled: PropTypes.bool,
};

export default Cross;
