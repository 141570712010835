import React from "react";
import PropTypes from "prop-types";

const NotSure = ({ color, filled }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="68"
      viewBox="0 0 68 68"
    >
      <g
        fill={filled ? color : "#fff"}
        fillRule="evenodd"
        transform="translate(-716 -699)"
      >
        <g>
          <circle
            cx="34"
            cy="34"
            r="33"
            stroke={filled ? "#fff" : color}
            strokeWidth="2"
            transform="translate(716 699)"
          ></circle>
          <g transform="translate(716 699) rotate(180 23.119 27)"></g>
        </g>
        <g fill={filled ? "#fff" : color}>
          <g strokeWidth="0.078">
            <path
              d="M32.785 53.899a3.084 3.084 0 01-2.343-2.14c-.118-.39-.14-.754-.14-2.22.002-1.524.025-1.856.178-2.555.23-1.05.915-2.916 1.473-4.008.366-.717.45-.954.475-1.347.034-.534-.1-.896-.396-1.064-.134-.077-1.282-.109-5.004-.139l-4.83-.039-.445-.209c-1.656-.776-2.204-2.727-1.173-4.185.345-.488.748-.796 1.376-1.051.474-.193.481-.227.067-.316-.14-.03-.468-.198-.73-.373a2.713 2.713 0 01-1.217-2.512c.093-1.154.874-2.07 2.121-2.488.128-.043.083-.09-.273-.285-.587-.32-.899-.645-1.19-1.235-.212-.432-.246-.584-.244-1.09.002-.476.044-.68.223-1.074.363-.797 1.178-1.445 1.953-1.551.193-.027.35-.059.35-.072s-.187-.21-.416-.44c-1.07-1.07-.798-2.653.566-3.283.354-.164.403-.166 6.472-.166 7.016 0 6.074-.08 9.97.856 1.135.273 2.274.532 2.531.577.506.087 3.734.476 3.955.476.127 0 .135.411.135 6.954v6.955l-.817.097c-1.5.178-2.255.525-2.897 1.333-.21.262-.84 1.426-1.596 2.941-.81 1.624-1.365 2.64-1.573 2.884-.325.38-.76.788-1.873 1.758-.853.743-1.242 1.227-1.639 2.038-.619 1.266-.854 2.321-.973 4.366-.098 1.684-.31 2.239-.97 2.539-.34.154-.593.17-1.106.068z"
              transform="translate(716 699)"
            ></path>
            <path
              d="M32.559 53.792c-1.132-.359-1.928-1.241-2.141-2.374-.104-.551-.102-3.108.003-3.861.148-1.061.77-2.879 1.533-4.483.345-.724.434-.996.468-1.42.046-.587-.06-.946-.322-1.092-.117-.065-1.562-.111-5.032-.16l-4.869-.068-.428-.202c-.617-.291-1.257-.958-1.475-1.537-.248-.66-.204-1.609.103-2.207a2.975 2.975 0 011.396-1.324c.287-.135.521-.273.521-.307s-.224-.158-.497-.277a3.02 3.02 0 01-1.427-1.313c-.196-.373-.218-.49-.218-1.192 0-.695.023-.822.21-1.175a3.118 3.118 0 011.427-1.328c.275-.123.502-.245.503-.271.001-.026-.206-.176-.46-.332-.562-.347-.816-.639-1.118-1.281-.207-.442-.228-.551-.195-1.06.042-.657.233-1.11.683-1.621.324-.37 1.114-.838 1.414-.84.422-.002.43-.122.039-.541-.52-.555-.697-.988-.658-1.602a1.885 1.885 0 011.534-1.75c.566-.105 11.066-.104 12.2.003.867.081 1.568.225 4.517.925 1.554.369 2.945.596 4.946.808l.896.095.048 2.804c.026 1.542.053 4.648.058 6.903l.011 4.1-.175.047c-.097.026-.28.048-.41.049-.45.004-1.665.299-2.076.504-.547.273-1.02.74-1.394 1.376-.167.285-.789 1.46-1.382 2.61-.593 1.15-1.169 2.232-1.28 2.404-.212.33-.931 1.045-2.18 2.17-1.659 1.496-2.299 3.115-2.514 6.359-.102 1.531-.342 2.167-.923 2.442-.316.15-.896.158-1.336.019z"
              transform="translate(716 699)"
            ></path>
          </g>
          <path
            d="M32.559 53.792c-1.16-.368-1.929-1.235-2.15-2.422-.055-.298-.073-1.104-.05-2.22.034-1.584.057-1.826.244-2.518.26-.963.798-2.387 1.36-3.597.344-.742.44-1.035.465-1.422.036-.55-.077-.913-.328-1.049-.113-.061-1.688-.114-5.113-.172l-4.95-.083-.453-.273c-.597-.358-1.09-.915-1.296-1.464-.24-.635-.191-1.595.11-2.184a3.018 3.018 0 011.4-1.331c.285-.136.518-.273.518-.304 0-.03-.229-.156-.509-.278a2.924 2.924 0 01-1.413-1.304c-.199-.378-.22-.492-.219-1.196 0-.662.029-.835.186-1.154.259-.523.792-1.027 1.42-1.344.295-.148.535-.281.535-.296 0-.015-.207-.155-.461-.312-.563-.347-.817-.639-1.119-1.281-.206-.44-.227-.552-.195-1.052.021-.327.104-.713.2-.93.306-.687 1.355-1.535 1.905-1.538.397-.003.4-.117.017-.549-.52-.585-.683-.989-.644-1.595a1.87 1.87 0 011.535-1.75c.567-.105 11.073-.103 12.195.003.901.086 1.57.22 4.21.847 1.921.456 2.594.574 4.635.811l1.518.177.041 2.06c.022 1.134.05 4.241.06 6.906l.02 4.845-.255.04c-.14.021-.43.06-.644.086-1.555.187-2.511.732-3.13 1.785a71.99 71.99 0 00-1.176 2.225c-1.448 2.831-1.55 2.993-2.41 3.823-.42.404-.985.927-1.257 1.162a6.35 6.35 0 00-1.55 1.986c-.566 1.13-.81 2.16-.956 4.05-.148 1.926-.346 2.503-.96 2.794-.316.15-.896.158-1.336.018z"
            transform="translate(716 699)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

NotSure.propTypes = {
  color: PropTypes.string,
  filled: PropTypes.bool,
};

export default NotSure;
