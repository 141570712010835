import React from "react";
import {
  Participant,
  Session,
  StyledUserHeader,
  UserHeaderImage,
  Column,
} from "./UserHeader.style";

const UserHeader = ({ name, image, session }) => {
  return (
    <StyledUserHeader>
      <Participant>{name}</Participant>
    </StyledUserHeader>
  );
};

export default UserHeader;
