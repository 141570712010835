import React from "react";
import PropTypes from "prop-types";

const Tick = ({ color, filled, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="68"
      viewBox="0 0 72 72"
      {...rest}
    >
      <g fill={filled ? color : "none"} fillRule="evenodd">
        <g transform="translate(-517 -691) translate(519 693)">
          <g stroke={filled ? "none" : color} strokeWidth="2.1">
            <circle cx="34" cy="34" r="34"></circle>
          </g>
        </g>
        <path
          fill={filled ? "#fff" : color}
          d="M29.401 50.704c-.531-.107-1.907-.422-3.058-.7-1.857-.447-2.59-.565-6.304-1.017-.015-.002-.027-3.11-.027-6.906v-6.903l1.057-.174c2.21-.363 2.468-.62 4.295-4.301l1.307-2.634 1.094-.997c1.851-1.687 2.201-2.085 2.655-3.023.56-1.157.844-2.416.958-4.241.11-1.767.31-2.284 1.01-2.626.388-.19.45-.191 1.093-.033 1.198.296 2.008 1.038 2.312 2.116.21.746.18 3.724-.048 4.752-.25 1.131-.948 3.006-1.538 4.133-.426.815-.477.985-.433 1.444.09.93-.234.872 5.315.935 5.46.062 5.278.038 6.15.825.659.596.895 1.125.897 2.017.003.635-.047.84-.316 1.328-.278.504-1.106 1.3-1.351 1.3-.047 0-.221.053-.387.117l-.302.117.497.19c.715.274 1.197.68 1.529 1.29.544.999.465 2.09-.215 2.98-.319.419-1.162.994-1.457.994-.266 0-.244.184.033.272.35.111 1.054.795 1.286 1.25.491.962.357 2.015-.36 2.83-.453.514-.968.828-1.575.96l-.37.079.335.282c1.081.914 1.033 2.456-.1 3.206l-.446.295-6.285.02c-5.7.017-6.375 0-7.25-.177z"
        ></path>
      </g>
    </svg>
  );
};

Tick.propTypes = {
  color: PropTypes.string,
  filled: PropTypes.bool,
};

export default Tick;
