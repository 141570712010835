import styled from "styled-components/macro";
import { device } from "../../theme";

export const StyledSelectTags = styled.div`
  display: block;

  min-width: 150px;
  position: relative;
  @media ${device.laptop} {
    flex-grow: 1;
  }
`;

export const Selected = styled.div`
  color: #fff;
  background: #008067;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;

  font-size: 0.75rem;
  text-align: left;
  cursor: pointer;
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    right: 10px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23fff' class='bi bi-caret-down-fill' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
    width: 16px;
    background-size: 16px 16px;
    height: 16px;
    top: 50%;
    transition: transform 0.4s;
    transform: translateY(-50%)
      ${(props) => (props.isActive ? "rotate(180deg)" : "")};

    transform-origin: center;
  }

  @media ${device.laptop} {
    display: none;
  }
`;

export const Options = styled.div`
  display: ${(props) => (props.visible ? "block" : "none")};
  position: absolute;
  background-color: #f9f9f9;
  min-width: 130px;
  font-size: 12px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  width: 100%;

  /* transform: translateX(-50%); */

  @media ${device.laptop} {
    box-shadow: none;
    max-width: auto;
    position: relative;
    display: flex;
    background-color: transparent;
    justify-content: space-between;
    width: 100%;
    box-shadow: none;
    transform: none;
  }
`;

export const Option = styled.button`
  border: none;

  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  display: block;
  /* min-width: 150px; */
  font-size: 0.75rem;
  display: block;
  width: 100%;
  text-align: left;
  cursor: pointer;
  position: relative;

  @media ${device.tablet} {
    border: 1px solid #979797;
    text-align: center;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  font-weight: bold;
  color: ${(props) => (props.selected ? "#fff" : "#008067")};
  border-color: ${(props) => (props.selected ? "#979797" : "transparent")};
  background-color: ${(props) => (props.selected ? "#008067" : "transparent")};
`;
