import styled from "styled-components/macro";

import { device } from "../../theme";

export const StyledCard = styled.div`
  background-color: #fff;

  box-shadow: 0 0 54px 0 rgba(0, 0, 0, 0.09);
  border-radius: 7px;
  padding: 20px;
  font-size: 0.9rem;
  width: 100%;
  display: flex;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  flex-direction: column;

  @media ${device.laptopL} {
    font-size: 1rem;
  }
`;

export const Heading = styled.h3`
  text-align: left;
  flex-grow: 1;
  font-weight: normal;
  color: #525252;
  font-size: 1.25rem;
  span {
    color: #525252;
    font-weight: bold;
  }
`;

export const Select = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Answer = styled.button`
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #ffffff;
  padding: 0;
  padding-top: 20px;
  font-weight: bold;
  margin-right: 5px;
  flex-wrap: wrap;
  cursor: pointer;
  border: none;
  flex-wrap: wrap;

  width: calc(100% / 3 - 5px);
  overflow: hidden;

  text-overflow: ellipsis;

  @media ${device.tablet} {
  }

  @media ${device.laptopL} {
    height: auto;
    width: calc(100% / 3 - 5px);
  }

  svg {
    max-width: 100%;
  }

  align-items: center;
`;

export const TextAfter = styled.div`
  font-size: 0.75rem;
  width: 100%;
  color: ${(props) => (props.color ? props.color : "#868686")};
  letter-spacing: 0;
  padding-top: 5px;
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;

  @media ${device.tablet} {
    font-size: 0.85rem;
  }

  @media ${device.laptop} {
    font-size: 0.8rem;
  }

  @media ${device.laptopL} {
    font-size: 0.98rem;
  }
`;
