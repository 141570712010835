import React, { useContext, useEffect, useReducer, useRef } from "react";

import {
  ButtonWrapper,
  Center,
  Content,
  Highlight,
  LargeText,
  LoadingWrapper,
  Notice,
  ProgressWrapper,
  Code,
  NoticeHeading,
  CodeInput,
  CodeInputLabel,
  Heading,
} from "./Lobby.style";

import { DispatchContext, StateContext } from "../../reducer";
import Progress from "../../components/Progress";
import Container from "../../components/Container";
import Loader from "../../components/Loader";
import Button from "../../components/Button/Button";

import useClipboard from "react-use-clipboard";
import { useTranslation } from "react-i18next";

const Lobby = () => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const { t } = useTranslation();
  const intervalRef = useRef(null);

  const scrollElementRef = useRef();

  const [isCopied, setCopied] = useClipboard(state.code, {
    // `isCopied` will go back to `false` after 1000ms.
    successDuration: 1000,
  });

  const handleChangeNickname = (e) => {
    dispatch({ type: "SET_NICKNAME", payload: e.currentTarget.value });
  };

  const handleCopyCode = () => {
    setCopied(state.code);
  };

  // So we're looking to see if the other player has joined!
  useEffect(() => {
    const callbackFn = () => {
      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/GetSession?code=${state.code}`
      )
        .then((res) => res.json())
        .then((res) => {
          if (res && res[2]) {
            dispatch({ type: "SET_PAGE", payload: "QUESTIONS" });
          }
        });
    };

    intervalRef.current = setInterval(callbackFn, 2000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [state.code]);

  const handleStartAgain = (e) => {
    e.preventDefault();
    window.location = "/";
  };

  return (
    <Container>
      <Heading>{t("index.heading")}</Heading>
      <Content>
        <Center>
          <LoadingWrapper>
            <Loader />
          </LoadingWrapper>
        </Center>

        <Notice>
          <div>
            <NoticeHeading>{t("lobby.waiting_for_player")}</NoticeHeading>
            <div>{t("lobby.share_code")}</div>
          </div>
        </Notice>
        <CodeInput
          type="text"
          showMessage={isCopied}
          onFocus={(e) => e.currentTarget.select()}
          onClick={handleCopyCode}
          value={state.code}
          id="code_input"
        />
        <CodeInputLabel for="code_input">
          {isCopied ? t("lobby.copied") : t("lobby.click_to_copy")}
        </CodeInputLabel>

        <ButtonWrapper>
          <Button color="outline" onClick={handleStartAgain} size="small">
            {t("index.button_start_over")}
          </Button>
        </ButtonWrapper>
      </Content>
    </Container>
  );
};

export default Lobby;
