import styled from "styled-components/macro";

export const StyledTextField = styled.input.attrs((props) => ({
  type: "text",
}))`
  border: 1px solid #d1d3d4;
  color: #3d434f;
  background-color: #f3f3f3;
  align-content: center;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  padding: 12px;
  display: block;
  width: 100%;
  text-align: center;
`;
